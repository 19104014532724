//
// Custom Example 1 Style
// Here you can put your custom sass code, use global SASS mixings and variables to override the theme or add new styles according to your requirements.
// Most of the changes can be achieved via global or layout level sass variable changes.
//

.store-selector-wrapper {
  align-items: center !important;
  margin: 0 10px;
}

.store-selector-label {
  margin: 0 10px;
}

.form-control-sm {
  padding: 0.2rem 0.5rem;
  font-size: 1rem;
}

.MuiTab-root {
  font-size: 1.05rem !important;
}

.react-select-all {
  height: 34px;
  margin: 2px 10px !important;
}

.icon-pointer {
  cursor: pointer;
}

@include kt-tablet-and-mobile {}
