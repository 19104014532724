@import "../../scss/variables";

.Toastify__progress-bar {
    background: #aeadd7 !important;
}
.Toastify__toast {
    min-height: 75px !important;
}
.toast-card {
    display: flex;

    &__alert {
        width: 100%;
        height: 80px;
        padding-right: 20px;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
    }

    &__icon {
        display: flex;
        align-items: center;
        font-size: 40px;
        height: 100%;
        padding-right: 20px;
        margin-left: 6px;

        &--success {
            color: $success;
        }

        &--error {
            color: $error;
        }

        i {
            padding: 5px;
        }
    }

    &__close-btn {
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
    }
}

html[dir="rtl"] {
    .toast-card__close-btn {
        top: 7px;
        right: unset;
        left: 7px;
    }

    .toast-card__icon,
    .toast-card__alert {
        padding-right: 0;
        padding-left: 20px;
    }

    .toast-card__icon {
        margin-left: unset;
        margin-right: 6px;
    }

    .Toastify__toast-container--bottom-right {
        bottom: 1em;
        left: 1em;
        right: unset;
    }
}
